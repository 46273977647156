import React from 'react';
import {graphql} from "gatsby";
import PropTypes from 'prop-types';
import CursoDetalhesView from "../views/curso-detalhes";

// MapBox map is powered by "localization" section from JSON files
// It will only be displayed if at least the following criteria are met:
// - course format should NOT be "online"
// - an "title" should be supplied in JSON file
// - longitude and latitude coordinates should be supplied in JSON file

const CursoDetalhes = (props) => {
  const {
    data,
  } = props;
  const course = data.course.nodes[0];
  
  return (
    <CursoDetalhesView course={course} />
  );
};

export default CursoDetalhes;

export const pageQuery = graphql`
query CourseDetailsQuery($iteration_id: String) {
  course: allRestApiCourseIterations(filter: {course_iter_id: {eq: $iteration_id}}) {
    nodes {
      faq {
        title
        value
      }
      applications_end_date
      applications_start_date
      course {
        name
        slug
      }
      image {
        localFile {
          childImageSharp {
            original {
              src
              height
              width
            }
            internal {
              mediaType
            }
          }
        }
      }
      additional_info
      description
      scholarity
      location
      short_description
      side_info {
        title
        value
      }
      topics
      course_iter_id
      iteration_name
      staff_users {
        description
        name
        roles
        image_id
        image {
          localFile {
            childImageSharp {
              fluid(quality: 80) {
                src
              }
            }
          }
        }
      }
    }
  }
}

`;


CursoDetalhes.propTypes = {
  path: PropTypes.string,
  data: PropTypes.shape({
    course: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        CursoDetalhesView.propTypes.course
      )
    })
  })
};